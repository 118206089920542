import { Box, Cluster, Text, ToggleSwitch } from '@a1s/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Duration } from 'utils/duration';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface TopProps {
  /**
   * What duration is selected
   */
  duration: Duration;

  /**
   * Whether or not live mode is on
   */
  isLiveMode: boolean;

  /**
   * Callback fired when the live mode toggle is changed
   */
  // eslint-disable-next-line no-unused-vars
  setIsLiveMode: (isLiveMode: boolean) => void;
}

export default function Top({ duration, isLiveMode = false, setIsLiveMode }: TopProps) {
  return (
    <Box testId="system-stats-box-top">
      <Cluster gap justify="stretch">
        <Box pr>
          <Cluster align="end" justify="end">
            <LiveModeToggle on={isLiveMode} onChange={setIsLiveMode} />
          </Cluster>
        </Box>
      </Cluster>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface LiveModeToggleProps {
  on: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (isLive: boolean) => void;
}

function LiveModeToggle({ on, onChange }: LiveModeToggleProps) {
  const { t } = useTranslation('dashboardHome');

  function handlePress() {
    onChange(!on);
  }

  const color = on ? 'green400' : 'gray400';

  return (
    <Cluster gap="2">
      <ToggleSwitch checked={on} color={color} onPress={handlePress} testId="system_stats-live-mode-toggle" />
      <Text color={`$${color}`} font="sans" size="sm" stretch="ultraCondensed" transform="uppercase" weight="medium">
        {t('liveMode')}
      </Text>
    </Cluster>
  );
}
